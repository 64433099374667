var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2" },
    [
      _c("static-text"),
      _c(
        "div",
        { staticClass: "md:w-3/5 m-0 w-full justify-self-end" },
        [
          _c(
            "custom-card",
            { attrs: { title: "Primeiro acesso" } },
            [
              _c("h4", [_vm._v("Olá " + _vm._s(_vm.nome) + ", tudo bem ?")]),
              _c(
                "p",
                {
                  staticStyle: {
                    "padding-top": "20px",
                    "padding-bottom": "20px"
                  }
                },
                [
                  _vm._v(
                    "\n        Esse é o seu primeiro acesso, cadastre sua senha, aceite os termos de\n        uso e clique em Iniciar primeiro acesso.\n      "
                  )
                ]
              ),
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var invalid = ref.invalid
                      return [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.refreshPassword()
                              }
                            }
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: { name: "Senha", rules: "required|min:6" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            size: "large",
                                            label: "Senha",
                                            type: "password"
                                          },
                                          model: {
                                            value: _vm.senha,
                                            callback: function($$v) {
                                              _vm.senha = $$v
                                            },
                                            expression: "senha"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ],
                                            staticClass: "text-danger text-sm"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(errors[0]) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("br"),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Confirmação de Senha",
                                rules: "required|min:6"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            size: "large",
                                            label: "Confirmação de Senha",
                                            type: "password"
                                          },
                                          model: {
                                            value: _vm.confirmacaoSenha,
                                            callback: function($$v) {
                                              _vm.confirmacaoSenha = $$v
                                            },
                                            expression: "confirmacaoSenha"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ],
                                            staticClass: "text-danger text-sm"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(errors[0]) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c(
                              "div",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.openModalTermosAceite()
                                  }
                                }
                              },
                              [
                                _c(
                                  "vs-checkbox",
                                  {
                                    staticClass: "mt-8 mb-4 mx-0",
                                    model: {
                                      value: _vm.termosAceite,
                                      callback: function($$v) {
                                        _vm.termosAceite = $$v
                                      },
                                      expression: "termosAceite"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Sim, concordo com os termos de uso do Portal\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass: "w-full mt-8 uppercase",
                                attrs: { disabled: invalid, size: "large" },
                                on: {
                                  click: function($event) {
                                    return _vm.refreshPassword()
                                  }
                                }
                              },
                              [_vm._v("Iniciar primeiro acesso")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "termos-modal",
          attrs: {
            fullscreen: "",
            "button-close-hidden": true,
            title: "Termos de Uso",
            active: _vm.assinarNovoTermoDeUso
          },
          on: {
            "update:active": function($event) {
              _vm.assinarNovoTermoDeUso = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-col items-center pt-4" },
            [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.newTermsOfUse.HTMLTermOfUse) }
              }),
              _c(
                "vs-button",
                {
                  staticClass: "self-end mt-2 float-right",
                  attrs: { size: "large", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.assignNewTermOfUse()
                    }
                  }
                },
                [_vm._v("\n        Li e concordo\n      ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }